body {
  padding: 0;
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  padding-top: 56px;
}

.MuiTypography-title-46 {
  flex-grow: 1;
}

.MuiAppBar-root-1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.warning {
  color: #ff9800;
}

input[type="search"] {
  -webkit-appearance: none;
}

::-webkit-search-cancel-button {
  display: none;
}
